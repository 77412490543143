<template>
  <div class="unpaid-analytics" v-if="hasPerm('payments.view_analyticaccount')">
    <page-header
      :title="'Synthèse des impayés au ' + today" icon="fas fa-chart-bar"
      :links="getLinks()"
    ></page-header>
    <div class="row">
      <div class="col-12">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div ref="printMe">
          <div class="hide-here">
            Synthèse des impayés au {{ today }}
          </div>
          <div class="header-bar no-print">
            <b-row>
              <b-col>
                <b-form-group label="Saison" label-for="schoolYearFilter">
                  <b-select v-model="schoolYearFilter" id="schoolYearFilter">
                    <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                      {{ schoolYear.name }}
                    </b-select-option>
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Analytiques" label-for="analyticAccountFilter">
                  <b-select v-model="analyticAccountFilter" id="analyticAccountFilter">
                    <b-select-option v-for="elt of analyticAccounts" :key="elt.id" :value="elt">
                      {{ elt.getLabel() }}
                    </b-select-option>
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col :cols="6">
                <date-frame-selector @change="onDateFrameChanged" :start-date="startDate" :end-date="endDate">
                </date-frame-selector>
              </b-col>
            </b-row>
          </div>
          <div ref="excelTable" v-if="!isLoading(loadingName)">
            <b-row>
              <b-col>
                <b>
                  <counter-label :counter="filteredElements.length" label="élément"></counter-label>
                  pour un montant de {{ unpaidTotal | currency }}
                </b>
              </b-col>
              <b-col class="text-right">
                <b v-if="startDate || endDate">
                  Seulement pour les factures {{ dateFilter }}
                </b>
              </b-col>
            </b-row>
            <table class="table table-striped">
              <tr>
                <th>Saison</th>
                <th>Code analytique</th>
                <th>Total</th>
                <th class="text-right number">{{ unpaidTotal | currency }}</th>
              </tr>
              <tr class="hide-here" >
                <th>
                  <div v-if="schoolYearFilter">{{ schoolYearFilter.name }}</div>
                </th>
                <th>
                  <div v-if="analyticAccountFilter">{{ analyticAccountFilter.getLabel() }}</div>
                </th>
                <th colspan="2">
                  {{ dateFilter }}
                </th>
              </tr>
              <tr v-for="element of filteredElements" :key="element.key">
                <td>
                  {{ element.schoolYear.name }}
                </td>
                <td colspan="2">
                  <span :class="{ italic: element.analyticAccount.id === 0, }">
                    {{ element.analyticAccount.getLabel() }}
                  </span>
                </td>
                <td class="text-right number">
                  {{ element.unpaidTotal | currency }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import router from '@/router'
import { dateToString } from '@/filters/texts'
import { makeSchoolYear } from '@/types/schools'
import { makeAnalyticAccount } from '@/types/payments'
import { distinct } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'

export default {
  name: 'unpaid-analytics',
  mixins: [BackendMixin],
  components: {
    DateFrameSelector,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'unpaid-analytics',
      elements: [],
      schoolYears: [],
      analyticAccounts: [],
      schoolYearFilter: null,
      analyticAccountFilter: null,
      startDate: null,
      endDate: null,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    today() {
      return moment().format('LLLL')
    },
    unpaidTotal() {
      return this.filteredElements.reduce(
        (acc, cur) => {
          acc += cur.unpaidTotal
          return acc
        },
        0
      )
    },
    filteredElements() {
      return this.elements.filter(
        elt => this.schoolYearFilter.id === -1 || elt.schoolYear.id === this.schoolYearFilter.id
      ).filter(
        elt => this.analyticAccountFilter.id === -1 || elt.analyticAccount.id === this.analyticAccountFilter.id
      )
    },
    dateFilter() {
      let text = ''
      if (this.startDate) {
        if (this.endDate) {
          text = 'du '
        } else {
          text = 'à partir du '
        }
        text += dateToString(this.startDate)
      }
      if (this.endDate) {
        if (text) {
          text += ' '
        } else {
          text += 'jusqu\''
        }
        text += 'au ' + dateToString(this.endDate)
      }
      return text
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onLoaded() {
      this.loadElements()
    },
    async loadElements() {
      this.startLoading(this.loadingName)
      let url = '/api/unpaid-analytics/?'
      if (this.startDate) {
        url += '&startDate=' + this.startDate
      }
      if (this.endDate) {
        url += '&endDate=' + this.endDate
      }
      const backendApi = new BackendApi('get', url)
      try {
        this.schoolYears = [makeSchoolYear({ id: -1, name: 'Toutes', })]
        this.analyticAccounts = [makeAnalyticAccount({ id: -1, name: 'Tous', })]
        const resp = await backendApi.callApi()
        this.elements = resp.data.map(
          elt => {
            const schoolYear = makeSchoolYear(elt.school_year)
            const analyticAccount = makeAnalyticAccount(elt.analytic_account)
            this.schoolYears.push(schoolYear)
            this.analyticAccounts.push(analyticAccount)
            return {
              key: '' + schoolYear.id + ':' + analyticAccount.id,
              schoolYear: schoolYear,
              analyticAccount: analyticAccount,
              unpaidTotal: elt.unpaid_total,
            }
          }
        )
        this.schoolYears = distinct(this.schoolYears)
        this.analyticAccounts = distinct(this.analyticAccounts)
        this.schoolYearFilter = this.schoolYears[0]
        this.analyticAccountFilter = this.analyticAccounts[0]
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Liste des impayés',
          callback: this.viewUnpaidList,
          icon: 'fa fa-file-invoice',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'synthese-impayes-pdf-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'synthese-impayes-xls-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    viewUnpaidList() {
      router.push({ name: 'invoices-to-pay', })
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadElements()
    },
  },
}
</script>

<style lang="less">
.italic {
  font-style: italic;
}
</style>
